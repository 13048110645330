<template>
    <div class="section">
        <navigation></navigation>
        <div class="sec-treeprof">
            <v-row class="mt-3" v-if="heritageTree.length !=0">
                <v-col cols="12" md="3">
                    <div class="d-flex justify-center">
                        <v-img
                        justify-center
                            alt="La Finca Tree"
                            class="shrink tree-img"
                            contain
                            :src="'https://sandbox-lafinca-web.wbagadion.com/img/' + heritageTree.img"
                            transition="scale-transition"
                            width="320"
                            height="320"
                        />
                    </div>

                    <div class="d-flex justify-center live-btn">
                        <v-btn
                            id="live-view"
                            class="mt-0 pt-0"
                            color="success"                        
                            >
                            <!-- :to="{name: 'MyTrees'}" -->
                            VIEW LIVE
                        </v-btn>
                    </div>
                    
                </v-col>
                <v-col cols="12" md="9">
                    <v-row>
                        <v-col cols="12" md="12" class="mt-5">
                            <v-simple-table dense class="tbl_info">
                                <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>{{heritageTree.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Grower</td>
                                        <td>{{heritageTree.grower_assigned}}</td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td>{{heritageTree.farm_location}}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{{heritageTree.status}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Planted</td>
                                        <td>{{heritageTree.date_planted}}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>                          
                        </v-col>   

                        <v-col cols="12" md="12" class="memories">
                            <div class="variety">Memories</div>
                            <div class="trees-variety d-flex">
                                <v-img
                                alt="La Finca Tree"
                                class="shrink mr-5"
                                contain
                                src="../assets/trees/balimbing.jpg"
                                transition="scale-transition"
                                width="50"
                                height="50"
                                />
                                <v-img
                                alt="La Finca Tree"
                                class="shrink mr-5"
                                contain
                                src="../assets/trees/balimbing.jpg"
                                transition="scale-transition"
                                width="50"
                                height="50"
                                />
                                <v-img
                                alt="La Finca Tree"
                                class="shrink mr-5"
                                contain
                                src="../assets/trees/balimbing.jpg"
                                transition="scale-transition"
                                width="50"
                                height="50"
                                />
                            </div>                            
                        </v-col>                                        
                    </v-row>                  
                </v-col>               
            </v-row>  

            <!-- <v-row class="mt-3">
                <v-col cols="12" md="12">
                    <div><h3>Information</h3></div>
                    <v-simple-table dense class="tbl_info">
                        <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Scientific Name</td>
                                <td>Lorem Ipsum</td>
                            </tr>
                            <tr>
                                <td>Plant Size</td>
                                <td>Juan Dela Cruz</td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td>Alteza</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>Sponsored</td>
                            </tr>
                            <tr>
                                <td>Date Planted</td>
                                <td>dd/mm/yyyy</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>           -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },
  data(){
      return{
        heritageTree: []
      }
    },
    created(){
      this.getHeritageTree(); 
    },
    methods:{
      getHeritageTree(){
        axios.get('https://lafincaapi.wbagadion.com/api/get/heritage_tree?id='+this.$route.params.id)
        .then(res => {
          console.log(res)
          this.heritageTree = res.data[0]
        })
        .catch(err => {
          console.error(err); 
        })
      }
    }
}
</script>

<style>
 .section{padding: 5px 65px 5px 65px;}
    .sec-treeprof{
        margin-top: 20px;
        
        text-align: justify;
    }
    .treeprof-title{
        font-size: 25px;
        font-weight: bold;
    }
    .variety{
        font-size: 18px;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      .section{
        padding: 0px;
      }
      .live-btn{margin-top: 15px;}
      .tbl_info{width: 90% !important; padding: 2px 10px;}
      .variety{text-align: center;}
      .trees-variety{
        justify-content: center;
        margin-top: 10px;
      }
      /* .trees-variety {
        display: flex !important;
        align-items: center !important;
      } */
    }
</style>

<style scoped>
    .tbl_info{
        width: 30%;
    }
</style>